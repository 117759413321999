import { graphql, useStaticQuery } from 'gatsby';

const content = [
  {
    type: 'image',
    imageKey: 'loyalty',
  },
  {
    type: 'section',
    title: 'Czym jest bruksizm?',
    nodes: [
      {
        type: 'paragraph',
        content:
          'Bruksizm, potocznie nazywany zgrzytaniem zębami, to zaburzenie funkcji mięśni żuchwy, które objawia się nieświadomym zaciskaniem lub zgrzytaniem zębami, szczególnie podczas snu. Stan ten może prowadzić do bólu żuchwy, uszkodzenia zębów i problemów związanych z stawem skroniowo-żuchwowym.',
      },
    ],
  },
  {
    type: 'section',
    title: 'Przyczyny bruksizmu',
    nodes: [
      {
        type: 'paragraph',
        content:
          'Przyczyny bruksizmu są różnorodne i mogą obejmować stres, nieprawidłowe ustawienie zębów, problemy ze snem, a także pewne typy leków. Zgrzytanie zębami często jest reakcją organizmu na stres lub lęk.',
      },
    ],
  },
  {
    type: 'section',
    title: 'Sposoby leczenia bruksizmu',
    nodes: [
      {
        type: 'paragraph',
        content:
          'Najczęściej stosowanymi metodami leczenia bruksizmu są: szyny okluzyjne, zmiany w stylu życia, a także zabiegi medycyny estetycznej, takie jak iniekcje  z użyciem toksyny botulinowej. Toksyna botulinowa, znana jako botoks, pomaga zmniejszyć napięcie mięśni odpowiedzialnych za zgrzytanie zębami, co przyczynia się do zmniejszenia objawów bruksizmu.',
      },
    ],
  },
  {
    type: 'section',
    title: 'Opis zabiegu z wykorzystaniem botoksu',
    nodes: [
      {
        type: 'paragraph',
        content:
          'Zabieg  toksyną botulinową,  mający na celu redukcję zgrzytania zębami,  polega na wstrzyknięciu preparatu bezpośrednio w mięśnie żwacze, co prowadzi do tymczasowego osłabienia ich napięcia. Lekarz medycyny estetycznej, po dokładnym zbadaniu stanu zdrowia pacjenta i omówieniu oczekiwań, wykonuje zabieg przy użyciu cienkiej igły, co minimalizuje dyskomfort.',
      },
    ],
  },
  {
    type: 'section',
    title: 'Efekty zabiegu redukującego zgrzytanie zębami',
    nodes: [
      {
        type: 'paragraph',
        content:
          'Efekty leczenia bruksizmu botoksem są zazwyczaj widoczne po kilku dniach od zabiegu, a pełne efekty leczenia mogą utrzymywać się od 3 do 6 miesięcy. Dla utrzymania długotrwałych efektów zalecane są regularne powtórzenia zabiegu.',
      },
    ],
  },
  {
    type: 'section',
    title: 'Cena',
    nodes: [
      {
        type: 'paragraph',
        content:
          '<b>Cena zabiegu</b> zaczyna się od <b>1 100 zł</b>. Przy pierwszej  wizycie odbierz swoją kartę lojalnościową i otrzymaj <b>-30% na czwarty zabieg medycyny estetycznej</b> wykonany w Klinice IDEALE.',
      },
    ],
  },
  { type: 'recommendations' },
  {
    type: 'section',
    title: 'Przeciwwskazania do leczenia bruksizmy toksyną botulinową',
    nodes: [
      {
        type: 'ul',
        content: [
          'Okres ciąży, połogu i karmienia piersią',
          'Choroby nerwowo-mięśniowe t.j. miastenia gravis, Zespół Lamberta-Eatona',
          'Stosowanie bądź odstawienie w przeciągu tygodnia poprzedzającego zabieg leków takich, jak: D-penicylamina, cyklosporyna, tubokuraryna, pankuronium, galamina, sukcynylocholina, linkomycyna, tetracyklina, polimyksyna, aminochinolony, aminoglikozydy',
          'Zaburzenia krzepnięcia',
          'Miejscowy stan zapalny skóry',
          'Choroby nowotworowe',
        ],
      },
    ],
  },
  {
    title: 'Dlaczego IDEALE?',
    nodes: [
      {
        type: 'paragraph',
        content:
          'Zapraszamy do IDEALE Medycyna Estetyczna Warszawa Wola, gdzie odzyskasz swoją pewność siebie w przyjemnej atmosferze. Nasz doświadczony lekarz medycyny estetycznej indywidualnie dopasuje odpowiednie zabiegi dla Ciebie, dzięki którym wyjdziesz od nas piękna, naturalna i promienna. Nasza klinika mieści się w samym sercu Woli, zapewniając łatwy dostęp dla naszych klientów.',
      },
      {
        type: 'paragraph',
        content:
          'W IDEALE skupiamy się na dostarczaniu najskuteczniejszych zabiegów medycyny estetycznej, które czerpiemy prosto z międzynarodowych kongresów i szkoleń . Dążymy do zapewnienia najwyższej jakości usług, a nasz zespół stale poszerza swoją wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.',
      },
      {
        type: 'paragraph',
        content:
          'Razem z nami odkryjesz potencjał swojej urody i poczujesz się w pełni zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu i profesjonalizmowi, abyś mógł cieszyć się pięknem, jakie tkwi w Tobie.',
      },
    ],
  },
];

export const useBruxismTreatment = () => {
  const images = useStaticQuery(graphql`
    query {
      loyalty: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return { images, content };
};
