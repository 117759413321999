import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { Subtitle } from 'views/offer/components/Subtitle';

import { resolveNodeContent } from '../../helpers';
import { Content } from '../Content';
import { Section } from '../Section';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1920px;
`;

export const ContentTemplate = ({ content, images }) => {
  return (
    <Content>
      {content.map(({ type, title, nodes, imageKey }, idx) => {
        if (type === 'recommendations') {
          return <RecommendationsSection key={idx} />;
        }
        if (type === 'image') {
          return (
            <Section key={idx}>
              <SImageWrapper>
                <Image
                  src={images[imageKey].childImageSharp}
                  alt="bruksizm leczenie ideale medycyna estetyczna warszawa wola"
                />
              </SImageWrapper>
            </Section>
          );
        }
        return (
          <Section key={title}>
            <Subtitle>{title}</Subtitle>
            {nodes.map((node) => resolveNodeContent(node))}
          </Section>
        );
      })}
    </Content>
  );
};
