import React from 'react';
import { Offer } from 'views/offer';
import { Header } from 'views/offer/components/Header';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

import { ContentTemplate } from '../../views/offer/components/content-template';
import { useBruxismTreatment } from '../../views/offer/content/useBruxismTreatment';

const BruxismTreatmentOffer = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const { images, content } = useBruxismTreatment();

  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.bruksizm}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>Leczenie bruksizmu</Header>
        <ContentTemplate content={content} images={images} />
      </Offer>
    </Layout>
  );
};
export default BruxismTreatmentOffer;

export const Head = () => <SEO tags={TAGS.bruksizm} />;
